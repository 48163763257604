<template>
  <div id="header">
    <table style="width: 100%">
      <tr>
        <td style="text-align: left; width: 25%">
          <span class="PageHeader">{{ configurationName }}</span>
          <div class="notificationChangeList">
            <span class="soundIcon" v-if="notificationSound === 0">🔈</span>
            <span class="soundIcon" v-else>🔊</span>
            <span
              @click="updateSound(0)"
              class="soundOption"
              :class="{ selectedSound: notificationSound === 0 }"
              >X</span
            >
            <span
              @click="updateSound(1)"
              class="soundOption"
              :class="{ selectedSound: notificationSound === 1 }"
              >1</span
            >
            <span
              @click="updateSound(2)"
              class="soundOption"
              :class="{ selectedSound: notificationSound === 2 }"
              >2</span
            >
            <span
              @click="updateSound(3)"
              class="soundOption"
              :class="{ selectedSound: notificationSound === 3 }"
              >3</span
            >
          </div>
        </td>
        <td style="text-align: center; width: auto;">
          <span class="PageHeader">
            <a href="http://www.nipst.org" target="_blank">NIPST</a>&nbsp;
            <a href="/show/config">LiveCad</a>
            <span v-if="syncHubConnecting === true">(Reconnecting)</span>
            <span
              v-if="syncHubConnecting === false && syncHubConnected === false"
              >(Disconnected)</span
            >
          </span>
        </td>
        <td style="text-align: right; width: 25%;">
          <span class="PageHeader">{{ now | date }}</span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
  data() {
    return {
      timeInterval: 0,
      now: new Date(),
    };
  },
  props: ["configurationName", "notificationSound"],
  computed: mapState({
    syncHubConnecting: (state) => state.syncHubConnecting,
    syncHubConnected: (state) => state.syncHubConnected,
  }),
  methods: {
    updateSound(soundId) {
      console.log("setting sound to " + soundId);
      this.$emit("onNotificationSoundChange", soundId);
    },
  },
  created() {
    this.timeInterval = setInterval(() => (this.now = new Date()), 1000 * 60);
  },
  filters: {
    date(value) {
      return moment(value).format("MMM DD, YYYY HH:mm");
    },
  },
};
</script>

<style scoped>
.soundIcon {
  font-size: x-large;
  margin-right: 2px;
  width: 35px;
  display: inline-block;
}
.notificationChangeList {
  margin-left: 10px;
  display: inline-block;
}
.notificationChangeList .soundOption {
  cursor: pointer;
  color: red;
  font-size: x-large;
  padding-left: 2px;
  padding-right: 2px;
  margin-top:-5px;
  margin-left: 2px;
  margin-right: 2px;
  width: 35px;
  display: inline-block;
  text-align: center;
}
.selectedSound {
  background-color: yellow;
}
</style>